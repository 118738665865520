@import '@sezane/front-css/elements/fonts';
@import '@sezane/front-css/main';
@import 'swiper/scss/pagination';

:root {
  --swiper-pagination-color: #fff;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-pagination-bottom: 16px;
  --swiper-space-between: 20px;
}
